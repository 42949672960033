import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer({margin = '2.5rem 0'}) {
    return (
        <div className="copyright-wrap" style={{ marginTop: margin }}>
            <div className="container pos-rel">
                <div className="row text-md-start text-center">
                    <div className="col-sm-12 col-md-auto copyright-text">
                        © Copyright 2023. | Created by <Link to="/">Cancer Fund</Link>
                    </div>
                    <div className="col-sm-12 col-md-auto ml-md-auto text-md-right text-center copyright-links">
                        <Link to="/terms-condition">Terms & Condition </Link> | 
                        <Link to="/privacy-policy"> Privacy Policy </Link>
                        {/* <Link to="/legal"> Legal</Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
