import React from 'react'
import { Link } from 'react-router-dom' 

export default function HeaderComponent(props) {
    const { currentPage = '' } = props
    return (
        <header className={`${currentPage === 'home' && 'header-style-fullwidth'}`}>
            <nav className="navbar navbar-expand-lg header-fullpage">
                <div className="container text-nowrap">
                    <div className="d-flex align-items-center w-100 col p-0 logo-brand">
                        <Link to="/">
                            <label className="navbar-brand rounded-bottom light-bg">
                                <img src="/assets/images/logo_dark.svg" alt="Cancer Fund" height={100}/>
                            </label>
                        </Link>
                    </div>
                    <div className="d-inline-flex request-btn order-lg-last col-auto p-0 align-items-center">
                        <Link to="/donate-now">
                            <p className="nav-link btn btn-default ml-3 donate-btn">Donate</p>
                        </Link>
                        <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <Link to="/">
                                <li className={`nav-item ${currentPage === 'home' && 'active'}`}>
                                    <p className="nav-link">Home</p>
                                </li>
                            </Link>
                            <Link to="/about-us">
                                <li className={`nav-item ${currentPage === 'about' && 'active'}`}>
                                    <p className="nav-link">About Us</p>
                                </li>
                            </Link>
                            <Link to="/donate-now">
                                <li className={`nav-item ${currentPage === 'donate' && 'active'}`}>
                                    <p className="nav-link">Donation</p>
                                </li>
                            </Link>
                            <Link to="/cancer-fund">
                                <li className={`nav-item ${currentPage === 'cancer' && 'active'}`}>
                                    <p className="nav-link">Cancer</p>
                                </li>
                            </Link>
                            <Link to="/contact-us">
                                <li className={`nav-item ${currentPage === 'contact' && 'active'}`}>
                                    <p className="nav-link">Contacts</p>
                                </li>
                            </Link>
                            <Link to="/news">
                                <li className={`nav-item ${currentPage === 'news' && 'active'}`}>
                                    <p className="nav-link">News</p>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
