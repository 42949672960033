import React from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'

export default function Terms() {
    return (
      <>
        <HeaderComponent/>
        <Bread currentPage='Terms & Conditions' image='breadcrumbs_bg3.jpg'/>
        <main id="body-content" className="body-non-overflow">
          <div className="container" style={{ paddingTop: 50 }}>
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 order-lg-last">
                  <h1>Terms of service</h1>
                  <div style={{ backgroundColor: '#D59B2D', padding: '0.7rem 1.2rem', borderRadius: 4, marginTop: 20, marginBottom: 30 }}>
                    <p style={{ textAlign: 'justify', color: '#eee' }}>
                      <b style={{ fontSize: 20 }}>*DISCLAIMER:</b> Our visitors acknowledge that: (1) CANCER FUND MUNKH FOUNDATION is not now by this website and the information shown herein offering any securities, nor soliciting an offer to buy any securities; (2) this website and the information herein shall not be construed as any description of the business of CANCER FUND MUNKH FOUNDATION in conjunction with any offering of securities; (3) the information contained on this website is subject to change without notice, and no one shall assume from the availability of this website and the information herein that the affairs of CANCER FUND MUNKH FOUNDATION have not changed since the date of this information.
                    </p>
                  </div>
                  <p style={{ textAlign: 'justify' }}>
                    We have set out below certain Terms of Use (“Terms”) which govern your use of this website (“Site”) and the CANCER FUND MUNKH FOUNDATION investment platform and trademarks. Please read the following Terms carefully before using this Site or opening an investment account. Please note that your use of this Site constitutes your agreement to these Terms and our Privacy Policy.
                    <br/><br/>
                    We reserve the right, in our sole discretion, to modify, alter, or otherwise change these Terms at any time with or without notice. Please review our Terms periodically for changes. Your use of the Site constitutes your acceptance and agreement to be bound by these changes without limitation, qualification or change. If at any time you determine that you do not accept these changes, you must immediately discontinue use of the Site.
                    <br/><br/>
                    <h2>Disclaimer of warranties</h2>
                    Although we attempt to provide accurate information, we do not guarantee the accuracy of information found on the Site. Your reliance on information found on this Site is at your own risk.
                    <br/><br/>
                    The Site, and all its contents, is provided to you “as is,” without warranty of any kind, either express or implied. We make no representations, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, non-infringement of the rights of third parties, data accuracy, systems integration, or quiet enjoyment.
                    <br/><br/>
                    CANCER FUND MUNKH FOUNDATION, our affiliates, suppliers and other service providers do not warrant or make any representations regarding the use or the results of the use of the materials in this site in terms of their timeliness, correctness, accuracy, reliability, or otherwise. While we make reasonable efforts to provide accurate and timely information, you should not assume that the information provided is always up to date or that this site contains all the relevant information available. We assume no obligation to verify or maintain the currency of any information provided on the Site.
                    <br/><br/>
                    <h2>Limitation of liability</h2>
                    Under no circumstances will CANCER FUND MUNKH FOUNDATION, our affiliates, suppliers, or anyone involved in creating this Site and its contents be liable for any damages or injury, including direct, indirect, incidental, special, consequential, punitive, or other damages resulting from any circumstance involving this Site or its content (including but not limited to damages or delay in operation or transmission, breach of security, line failure, defamatory, offensive or illegal conduct of any user of this site, or computer virus, worm, Trojan Horse or other harmful component), even if you have advised us in advance of the possibility of such damage.
                    <br/><br/>
                    <h2>Use of intellectual property</h2>
                    The Site, and all of its contents, including but not limited to articles, other text, photographs, images, illustrations, graphics, video material, audio material, software, logos, titles, characters, names, and button icons (collectively the “intellectual property”), are protected by copyright, trademark and other laws of the United States, as well as international conventions and the laws of other countries. The intellectual property including trademarks, service marks, company names, and logos appearing on this Site were provided with the permission of their owner. No portion of this Site may be reproduced, duplicated, copied, sold, or otherwise exploited for any commercial purpose that is not expressly permitted in writing.
                    <br/><br/>
                    You may browse through the Site and occasionally download small amounts of material appearing on the Site that are of personal interest to you for your personal, non-commercial use. You must keep intact all copyright, trademark and other notices contained in your personal copies. You may not reproduce or allow others to reproduce your personal copies of downloaded materials, nor may you make them available electronically. You may not save or archive a significant portion of the material appearing on the Site. You may not attempt to alter or modify the content posted on the Site. Except as expressly set forth in this paragraph, you may not copy, download, display, distribute, publish, enter into a database, perform, modify, create derivative works, transmit, post, decompile, reverse engineer, disassemble or in any way exploit any of our intellectual property or the Site itself.
                    <br/><br/>
                    <h2>Proper use of our site</h2>
                    You may not use this Site for any purpose that is unlawful or prohibited by our Terms, or cause damage on or through the Site. You promise that none of your communications with or through the Site will violate any applicable local, state, national or international law. Your access to the Site may be terminated immediately in CANCER FUND MUNKH FOUNDATION’s sole discretion, with or without notice, if you fail to comply with any provision of our Terms.
                    <br/><br/>
                    <h2>Modification and termination</h2>
                    We reserve the right, in its sole discretion, to modify, suspend, or terminate the Site and/or any portion thereof at any time for any reason with or without notice to you. We shall not be liable to you or any third party for any termination of your access to the Site.
                    <br/><br/>
                    <h2>Indemnification</h2>
                    You agree to indemnify, defend, and hold harmless CANCER FUND MUNKH FOUNDATION, our affiliates, officers, directors and employees from and against any and all claims, actions, demands, liabilities, costs and expenses, including, without limitation, reasonable attorneys’ fees, resulting from your use of the Site in a manner that violates or is alleged to violate these Terms, any applicable law, or any warranty you provide herein, or otherwise arising in any way out of your use of the Site. We reserve the right to take exclusive control and defense of any such claim otherwise subject to indemnification by you, in which event you will cooperate fully with us in asserting any available defenses.
                    <br/><br/>
                    <h2>Links and frames</h2>
                    The Site may contain links to other web or news sites operated by third parties and may also provide third party content on our Site by “framing” or other methods. We do not monitor or control the content of any linked or framed sites and make no representations regarding, and are not liable or responsible for, the accuracy, completeness, timeliness, reliability or availability of any of the content uploaded, displayed, or distributed, or products or services available at these sites. If you choose to access any third party site or rely on information found within third party content appearing on this Site, you do so at your own risk. The presence of a link to a third party site does not constitute or imply our endorsement, sponsorship, or recommendation of the third party or of the content, products or services contained on, or available through, the site.
                    <br/><br/>
                    <h2>Feedback and submissions</h2>
                    Except as described in Site’s Privacy Policy, any material, information or ideas you submit to this Site will be deemed a grant of a royalty free, exclusive, sub-licensable (through multiple tiers) right and license to use, reproduce, cache, modify, display, publicly perform, transmit, adapt, publish, translate, create derivative works from and distribute these materials throughout the universe in any medium and through any methods of distribution, transmission and display whether now known or hereafter devised. In addition, you warrant that all publicity rights and so-called “moral rights” have been waived. If any court determines that we do not retain exclusive ownership of any such material, information, or ideas, then we still retain a non-exclusive license to use them as set forth in our licensing agreements.
                    <br/><br/>
                    For any questions or comments regarding these Terms or the use authorized use of our intellectual property please call <b>(976)-88031200</b> or email <a href="mailto:cancerfundmn@gmail.com">cancerfundmn@gmail.com</a> 
                  </p>
                </div>
              </div>
          </div>  
        </main>
        <Footer />
      </>
    )
}
