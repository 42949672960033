import React, { useRef } from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'

export default function ContactUs() {
  const captchaRef = useRef(null)
  return (
    <>
      <HeaderComponent currentPage="contact"/>
      <Bread currentPage='Contact Us' image='breadcrumbs_bg6.jpg'/>
      <main id="body-content">
        <section>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-8 col-md-12 order-lg-last">
                <div className="contact-wrap">
                  <div id="contact_form">
                    <form className="row" action='?submited=success'>
                      <div className="col-md-6 mb-0">
                        <div className="form-group">
                          <input type="text" name="name" id="name" className="form-control" placeholder="First Name"/>
                        </div>
                      </div>
                      <div className="col-md-6 mb-0">
                        <div className="form-group">
                          <input type="text" name="lastname" id="lastname" className="form-control" placeholder="Last Name"/>
                        </div>
                      </div>
                      <div className="col-md-6 mb-0">
                        <div className="form-group">
                          <input type="text" name="email" id="email" className="form-control" placeholder="Your Email"/>
                        </div>
                      </div>
                      <div className="col-md-6 mb-0">
                        <div className="form-group">
                          <input type="number" name="phone" id="phone" className="form-control" placeholder="Phone Number"/>
                        </div>
                      </div>
                      <div className="col-md-12 mb-0">
                        <div className="form-group">
                          <textarea name="comment" id="comment" className="form-control" rows="6" placeholder="Message"></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary text-nowrap">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">

              <div className="icon-box-4 bg-orange mb-4">
                <i data-feather="map-pin"></i>
                <h3>Address</h3>
                <div>Mongolia, Ulaanbaatar, <br/> Bayangol district</div>
              </div>


              <div className="icon-box-4 bg-green mb-4">
                <i data-feather="phone"></i>
                <h3>Phone</h3>
                <div>(+976) 88031200</div>
              </div>


              <div className="icon-box-4 bg-gray mb-4">
                <i data-feather="mail"></i>
                <h3>Email</h3>
                <div>
                  <a href="mailto:cancerfundmn@gmail.com"><span>cancerfundmn@gmail.com</span></a>
                </div>
              </div>

              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
