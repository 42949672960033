import React from 'react'

export default function BannerComponent() {
  return (
    <section className="main-banner home-style-second">
        <div className="slides-wrap">
            <div className='owl-slide d-flex align-items-center cover' style={{width: '100%', height: 700, backgroundImage: 'url('+ require('./../images/web/slider_home_first_3.jpg') +')'}}>
                <div className="container">
                    <div className="row justify-content-center justify-content-md-start no-gutters">
                        <div className="col-10 col-md-6 static">
                            <div className="owl-slide-text">
                                <h3 className="owl-slide-animated owl-slide-title" style={{
                                    textShadow: '2px 2px 20px rgba(0, 0, 0, 0.6)',
                                }}>Empowering cancer patients</h3>
                                <h1 className="owl-slide-animated owl-slide-subtitle" style={{
                                    textShadow: '2px 2px 20px rgba(0, 0, 0, 0.6)',
                                }}>
                                    To live with strength and dignity.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
