import React, { useEffect, useState } from 'react'
import config from './../../config/host'
import axios from 'axios'
import HeaderComponent from '../../components/header'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import moment from 'moment/moment'

export default function NewsRead(props) {
    const { history } = props
    const { api, file } = config
    const id = props.location.pathname.split('/')[props.location.pathname.split('/').length - 1].split('.')[0] || 0
    const [data, setData] = useState([])
    useEffect(() => {
        if(!id || id === 0) history.push("/")
        axios.get(`${api}news/${id}`)
        .then(({data}) => {
            if(!data.success) history.push("/")
            setData(data?.data || [])
        })
        .catch(() => history.push('/'))
    }, [history])
    return (
        <>
            <HeaderComponent/>
            <Bread currentPage={data?.title || ''} image='breadcrumbs_bg8.jpg'/>
            <main id="body-content" className="body-non-overflow">
                <section class="wide-tb-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="sidebar-spacer">
                                    <div class="d-flex">
                                        <div class="post-date txt-blue">{moment(data?.created_at).format('DD, MMMM, YYYY')}</div>
                                        <div class="mx-2">/</div>
                                        <div class="post-category">{data?.tags || ''}</div>
                                    </div>
                                    <h1 class="heading-main">{data?.title || ''}</h1>

                                    <div class="causes-wrap single">
                                        <div class="img-wrap">
                                            <img src={`${file}news/${data?.cover || ''}`} alt={data?.title || ''} />
                                        </div>
                                        <div class="content-wrap-single">
                                            <div dangerouslySetInnerHTML={{__html: data?.body || ''}}></div>
                                        </div>
                                        <div class="share-this-wrap">
                                            <div class="share-line">
                                                <div class="pr-4">
                                                    <strong>Office Of Publications</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
