import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GuestRoute } from "./protect/route";
import HomePage from "./pages/home/homepage";
import AboutUs from "./pages/about/us";
import CancerFund from "./pages/cancer/cancer";
import ContactUs from "./pages/contact/contact_us";
import DonateNow from "./pages/donate/donate";
import News from "./pages/news/news";
import NewsRead from "./pages/news/read";
import Policy from "./pages/privacy/policy";
import Terms from "./pages/privacy/terms";


import NotFound from "./pages/notfound";

function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <Switch>
        <GuestRoute exact path="/" component={HomePage} />
        <GuestRoute exact path="/about-us" component={AboutUs} />
        <GuestRoute exact path="/cancer-fund" component={CancerFund} />
        <GuestRoute exact path="/contact-us" component={ContactUs} />
        <GuestRoute exact path="/donate-now" component={DonateNow} />
        <GuestRoute exact path="/news" component={News} />
        <Route exact path="/news/:id.htm" component={NewsRead} />
        <GuestRoute exact path="/privacy-policy" component={Policy} />
        <GuestRoute exact path="/terms-condition" component={Terms} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
