import React from 'react'
import { Link } from 'react-router-dom'

export default function Bread({currentPage = '', image = 'breadcrumbs_bg.jpg'}) {
    return (
        currentPage && <section className="breadcrumbs-page-wrap">
            <div className="bg-fixed pos-rel breadcrumbs-page" style={{ backgroundImage: 'url("/assets/images/'+image+'")' }}>
                <div className="container">
                    <h1>{ currentPage }</h1>
                    <nav aria-label="breadcrumb" className="breadcrumb-wrap">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{ currentPage }</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>
    )
}
