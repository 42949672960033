import React from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'

export default function Policy() {
    return (
      <>
        <HeaderComponent/>
        <Bread currentPage='Privacy Policy' image='breadcrumbs_bg9.jpg'/>
        <main id="body-content" className="body-non-overflow">
            <div className="container" style={{ paddingTop: 50 }}>
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 order-lg-last">
                        <h1>Privacy policy</h1>
                        <p style={{ textAlign: 'justify' }}>
                            <small>Effective Date: March 3, 2023</small>
                            <br /><br />
                            <h2>Our policies on collecting & protecting your information</h2>
                            We recognize that protecting the information our clients provide to us is extremely important, and we take this responsibility very seriously. Please read the description below to better understand how we collect and utilize your business and personal information.
                            <br /><br />
                            <b>CANCER FUND MUNKH FOUNDATION</b> is providing this notice to explain our information collection practices and other important information.
                            <br /><br />
                            <h2>Information we collect</h2>
                            We collect non-public personal information about you from the following sources:
                            <br /><br />
                            Information we receive about you when you open an online investment account and other forms including subscription documents or questionnaires. This information may include your name, address, telephone number, e-mail address, social security number, income and other financial data, investment experience, etc.
                            Information about your transactions with us, our affiliates and others. This includes information about the investment opportunities offered on this website or utilizing the CANCER FUND MUNKH FOUNDATION’s associated funding platform(s) and services and others (including any affiliated companies).
                            <br /><br />
                            Information we receive about you from third parties such as referring brokers or advisers, managers, product sponsors and clearing firms, and/or their service providers. These parties may include affiliates of ours.
                            <br /><br />
                            <h2>Disclosure of your information</h2>
                            We do not disclose any personal or financial information about you, whether you are our current or former client, without your authorization or direction, except as disclosed below.
                            <br /><br />
                            We may share information about you with affiliated or non-affiliated third parties, in order for them to assist us in providing services to you, and otherwise as permitted by law. However, affiliated and non-affiliated third parties who receive information about you in order to assist us in providing services to you are restricted from using your information for any other purpose.
                            <br /><br />
                            If there is a change to our Privacy Policy, the new policy will be posted on our website, www.cancerfund.mn, and attached to any notice of such change, with a visible effective date.
                            <br /><br />
                            <h2>Confidentiality and security</h2>
                            We restrict access to non-public personal information about you to those employees and authorized third parties who need to know that information to assist us in providing services to you or your business. We maintain physical, electronic and procedural safeguards that comply with federal standards to guard your non-public information.
                            <br /><br />
                            <b>FOR QUESTIONS OR CONCERNS:</b><br/>
                            <b>CANCER FUND MUNKH FOUNDATION</b><br/>
                            <a href="mailto:cancerfundmn@gmail.com"><b>Cancerfundmn@gmail.com</b></a><br/>
                            <b>+976-88031200</b>
                        </p>
                    </div>
                </div>
            </div>
        </main>
        <Footer margin={0}/>
      </>
    )
}
