import React from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'

export default function Cancer() {
  return (
    <>
      <HeaderComponent currentPage="cancer"/>
      <Bread currentPage='Cancer situation in mongolia' image='breadcrumbs_bg2.jpg'/>
      <main id="body-content" className="body-non-overflow" style={{ backgroundColor: '#fff' }}>
            <section class="wide-tb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p style={{ textAlign: 'justify' }}>
                            <span class="dropcap2 bg-orange">C</span>
                            ancer is a major public health concern in Mongolia, with the highest cancer mortality rate in the world. It is the leading cause of death in the country, accounting for 25% of all deaths. The incidence of cancer is increasing, with an estimated 13,000 new cases diagnosed each year. The most common types of cancer in Mongolia are liver cancer, stomach cancer, and lung cancer. However, other types of cancer, such as breast cancer and colorectal cancer, are also increasing in incidence.
                            <br/><br/>
                            Several factors contribute to the high cancer mortality rate in Mongolia. Poor lifestyle habits, such as smoking and excessive alcohol consumption, are common in Mongolia and are leading contributors to the development of cancer. Additionally, there is a lack of early detection and treatment options, as well as a shortage of oncologists and infrastructure to properly diagnose and treat cancer patients.
                            <br/><br/>
                            The cost of cancer treatment in Mongolia can be prohibitively high, with many patients unable to afford the high cost of chemotherapy, radiation therapy, and other treatments. According to the Mongolian National Cancer Center, approximately 60% of cancer patients in Mongolia have low incomes and are unable to afford treatment.
                            <br/><br/>
                            The government of Mongolia is working to address the high cancer mortality rate in the country, but the resources available are limited. The Cancer Support "Munkh" Foundation is dedicated to supporting cancer patients and their families, particularly those with low incomes. Our foundation provides financial and emotional support to help ease the burden of cancer and improve the quality of life for cancer patients.
                            <br/><br/>
                            Through our work, we aim to raise awareness about cancer and the need for support and assistance for cancer patients in Mongolia. Our foundation is committed to helping cancer patients access the resources and care they need to fight cancer and live healthy, fulfilling lives. Join us in our mission to make a difference in the fight against cancer in Mongolia.
                            <br/><br/>
                            <ul className="list-unstyled icons-listing theme-orange mb-0">
                              <li>Mongolia has the highest cancer mortality rate in the world, with an estimated 9,000 cancer deaths per year.</li>
                              <li>Cancer is the leading cause of death in Mongolia, accounting for 25% of all deaths.</li>
                              <li>The most common types of cancer in Mongolia are liver cancer, stomach cancer, and lung cancer. Other types of cancer, such as breast cancer and colorectal cancer, are also increasing in incidence.</li>
                              <li>Many factors contribute to the high cancer mortality rate in Mongolia, including poor lifestyle habits, such as smoking and excessive alcohol consumption, a lack of early detection and treatment options, and a lack of access to quality healthcare.</li>
                              <li>According to the World Health Organization (WHO), Mongolia has a shortage of oncologists and lacks the infrastructure and resources to properly diagnose and treat cancer patients.</li>
                              <li>The cost of cancer treatment in Mongolia can be prohibitive, with many patients unable to afford the high cost of chemotherapy, radiation therapy, and other treatments.</li>
                              <li>According to the Mongolian National Cancer Center, approximately 60% of cancer patients in Mongolia have low incomes and are unable to afford treatment.</li>
                              <li>The estimated cancer mortality rate in Mongolia is 172 per 100,000 people, which is more than double the global average of 84 per 100,000 people.</li>
                              <li>The incidence of liver cancer in Mongolia is particularly high, with an estimated 50% of all cancer cases being liver cancer. Mongolia has the highest incidence of liver cancer in the world.</li>
                              <li>Stomach cancer is the second most common cancer in Mongolia, accounting for 16% of all cancer cases.</li>
                              <li>The five-year survival rate for all types of cancer in Mongolia is estimated to be around 20%, which is much lower than the global average of around 50%.</li>
                              <li>According to the Mongolian National Cancer Center, only 5-10% of cancer cases in Mongolia are diagnosed at an early stage, which reduces the chances of successful treatment.</li>
                              <li>The cost of cancer treatment in Mongolia can be up to 10 times higher than the average monthly salary, making it difficult for many cancer patients to access the care they need.</li>
                            </ul>
                            These statistics highlight the severity of the cancer situation in Mongolia and the urgent need for support and assistance for cancer patients. The Cancer Support "Munkh" Foundation is committed to helping cancer patients access the resources and care they need to fight cancer and live healthy, fulfilling live.

                            </p>
                            <img src={require('./../../images/pdf/1_1.jpg')} alt="Cancerfund" style={{ width: '100%', marginTop: 40 }}/>
                            <img src={require('./../../images/pdf/1_2.jpg')} alt="Cancerfund" style={{ width: '100%', marginTop: 40 }}/>
                            <img src={require('./../../images/pdf/2_1.jpg')} alt="Cancerfund" style={{ width: '100%', marginTop: 40 }}/>
                            <img src={require('./../../images/pdf/2_2.jpg')} alt="Cancerfund" style={{ width: '100%', marginTop: 40 }}/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
      <Footer margin={0}/>
    </>
  )
}
