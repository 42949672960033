import React, { useEffect, useState } from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'
import axios from 'axios'
import moment from 'moment'
import config from './../../config/host'

export default function News(props) {
    const { history } = props
    const { api, file } = config
    const [data, setData] = useState([])
    useEffect(() => {
      axios.get(`${api}news`)
      .then(({data}) => {
          if(!data.success) history.push("/")
          setData(data?.data || [])
      })
      .catch(() => history.push('/'))
    }, [history])

    String.prototype.limit = function(length) {
      return this.length > length ? (this.substring(0, length) + '...') : this;
    }

    return (
      <>
        <HeaderComponent currentPage="news"/>
        <Bread currentPage='News' image='breadcrumbs_bg7.jpg'/>
        <main id="body-content">
          <section class="wide-tb-100">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="sidebar-spacer">
                    <div class="row">

                      {
                        data.length > 0 && data.map((el, index) => (
                          <div class="col-md-4 mb-0">
                            <div class="post-wrap">
                              <div class="post-img">
                                <a href={`/news/${el.id}.htm`}><img src={`${file}news/${el?.cover || ''}`} alt={el.title} style={{ height: 250 }}/></a>
                              </div>
                              <div class="post-content">
                                <div class="post-date">{moment(el?.created_at).format('DD, MMMM, YYYY')}</div>
                                <div style={{ height: 55, overflow: 'hidden', marginBottom: 8 }}>
                                  <h3 class="post-title"><a href={`/news/${el.id}.htm`}>{el.title.limit(40) || ''}</a></h3>
                                </div>
                                <div class="post-category">{el?.tags || ''}</div>
                                <div class="text-md-right">
                                  <a href={`/news/${el.id}.htm`} class="read-more-line"><span>Read More</span></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    )
}
