import React from 'react'
import Bread from '../../components/bread'
import Footer from '../../components/footer'
import HeaderComponent from '../../components/header'

export default function AboutUs() {
    return (
      <>
        <HeaderComponent currentPage="about"/>
        <Bread currentPage='About Us' image='breadcrumbs_bg4.jpg'/>
        <main id="body-content" className="body-non-overflow">
            <section class="wide-tb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p style={{ textAlign: 'justify' }}>
                              <span class="dropcap2 bg-orange">W</span>
                              elcome to Cancer Support "Munkh" Foundation, a non-profit organization dedicated to supporting people with cancer in Mongolia. Our mission is to provide financial and emotional support to cancer patients, especially those with low incomes, lone mothers and fathers who cannot afford treatment, and those who need support for their families.
                              <br/><br/>
                              Cancer is a devastating disease that affects millions of people worldwide, and Mongolia has the highest cancer mortality rate in the world. The government's resources are exhausted in the fight against cancer, leaving many patients without the necessary financial and emotional support to fight the disease.
                              <br/><br/>
                              At Cancer Support "Munkh" Foundation, we believe that no one should have to choose between their health and financial stability. We are committed to helping cancer patients and their families by providing financial assistance to cover medical expenses, as well as emotional support to help patients and their families cope with the challenges of cancer. Our foundation aims to ease the burden on cancer patients and their families by providing support that helps to reduce stress and improve their quality of life.
                              <br/><br/>
                              Our foundation is focused on supporting people with cancer during treatment and after treatment to stabilize their financial situation. Cancer patients often lose a significant amount of money during their cancer journey due to medical expenses and the inability to work. Our foundation provides financial assistance to help cancer survivors stand up financially after treatment, providing them with the opportunity to rebuild their lives.
                              <br/><br/>
                              We are passionate about making a positive impact in the fight against cancer in Mongolia. To this end, we donate equipment to oncology centers, provide training and support for cancer patients, and fundraise to support our cause. We believe that through collaboration and community involvement, we can make a difference in the fight against cancer.
                              <br/><br/>
                              At Cancer Support "Munkh" Foundation, we are dedicated to helping cancer patients and their families. Our foundation's values are rooted in compassion, empathy, and the belief that every cancer patient deserves access to quality care and support. Join us in our mission to support cancer patients and make a difference in the fight against cancer in Mongolia.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer />
      </>
    )
}
